<template>
  <div>
    <AuInput
      id="id-login"
      ref="login"
      v-model="email"
      label="E-mail"
      :error="formErrors.email && formErrors.email[0]"
      :readonly="loading"
      @input="$delete(formErrors, 'email')"
      @keyup.enter="lookup"
    />

    <template v-if="!showPassword">
      <!-- TODO: Extract classes -->
      <button
        class="btn btn-lg btn-block btn-lighten-primary rounded-pill my-5"
        :disabled="(email ? false : true) || loading"
        style="font-weight: 600;"
        @click="lookup"
      >
        Continuar
      </button>

      <div class="ou">
        ou
      </div>

      <!-- TODO: Extract classes -->
      <router-link
        class="btn btn-lg btn-block btn-light rounded-pill mt-5"
        style="color: #5C6167; font-weight: 600;"
        :to="{ name: 'Register' }"
      >
        Cadastre-se com código de turma
      </router-link>
    </template>

    <template v-else>
      <AuPasswordInput
        id="password"
        ref="password"
        v-model="password"
        class="mt-5"
        label="Senha:"
        :error="formErrors.password && formErrors.password[0]"
        :readonly="loading"
        :reveal="revealPassword"
        @keydown.enter="login"
        @input="$delete(formErrors, 'password')"
        @toggle="revealPassword = !revealPassword"
      />

      <!-- TODO: Extract styles to classes -->
      <button
        class="btn btn-lg btn-block btn-lighten-primary rounded-pill my-5"
        :disabled="email ? false : true"
        style="font-weight: 600;"
        @click="login"
      >
        Entrar
      </button>

      <div class="mt-5 text-center">
        Esqueceu a senha?
        <a
          href="#"
          class="au-auth-link"
          @click="forgotPass"
        >
          Recupere aqui
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import AuInput from '@/components/AudeUi/AuInput'
import AuPasswordInput from '@/components/AudeUi/AuInput/AuPasswordInput.vue'
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import utils from '@/api/utils'

export default {
  components: {
    AuInput,
    AuPasswordInput
  },

  mixins: [
    handlesServerValidation
  ],

  data: () => ({
    loading: false,
    showPassword: false,
    email: '',
    password: '',
    revealPassword: false
  }),

  mounted () {
    this.$refs.login.focus()
  },

  methods: {
    async lookup () {
      this.loading = true
      this.showPassword = false
      this.password = ''
      const data = {
        email: this.email
      }
      try {
        await this.$auth.lookUp(data)
        this.showPassword = true

        // Hack para mover o foco para o <input> dentro do componente AuInput.
        // No momento da execução desta função, o input da senha ainda não
        // existe no DOM. Com a $nextTick, podemos agendar um callback para
        // depois da próxima atualização de DOM do Vue.js.
        // Veja https://v2.vuejs.org/v2/guide/reactivity.html#Async-Update-Queue
        this.$nextTick(function () {
          this.$refs.password.focus()
        })
      } catch (e) {
        this.loading = false
        this.showPassword = false
        this.getErrors(e)
      }

      this.loading = false
    },

    async login () {
      const data = {
        email: this.email,
        password: this.password
      }
      this.loading = true
      try {
        await this.$auth.login(data)
        await this.$api.me()
        this.$router.push({ name: 'Enrollment' })
      } catch (e) {
        this.loading = false
        this.getErrors(e)
      }
      this.loading = false
    },

    async forgotPass (e) {
      e.preventDefault()

      this.loading = true
      const data = {
        email: this.email
      }
      try {
        await this.$api.forgotPass(data)
        this.$emit('sendEmail', 'Enviamos para você um e-mail com instruções para alterarmos sua senha.')
      } catch (e) {
        this.loading = false
        utils.showFeedback('fail', 'Ocorreu um erro, por favor tente novamente mais tarde. ')
        this.getErrors(e)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ou {
  position: relative;
  color: #8f9195;
  text-align: center;
}
.ou::before,
.ou::after {
  border-top: 1px solid black;
  display: block;
  height: 1px;
  content: "";
  width: 40%;
  position: absolute;
  top: 12px;
  opacity: 0.1;
}
.ou::after {
  right: 0;
  left: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.au-auth-link {
  color: #A37B29;
  text-decoration-color: #A37B29;
}
</style>
